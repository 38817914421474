<template>
  <div class="ffxiv-cards-edit">
    <v-row dense>
      <v-col cols="12" md="4" sm="12" lg="4" xl="4">
        <v-card height="650" dark>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-text-field class="ma-1" label="Search name">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-btn v-if="authCheck()" @click="newNpcBtnClick" class="ma-4">新加</v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-card height="500" class="overflow-y-auto overflow-x-hidden">
                  <v-row dense>
                    <v-col v-for="item in npcInfoList" :key="item.id" cols="12">
                      <v-card
                        color="#212121"
                        @click="selectNpcCard(item)"
                        :loading="dialog.addNpc.loading"
                      >
                        <div class="d-flex flex-no-wrap justify-space-between">
                          <div>
                            <v-card-title>{{ item.name }}</v-card-title>

                            <v-card-subtitle>{{
                              item.position
                            }}</v-card-subtitle>

                            <v-card-text>
                              {{ item.describe }}
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                @click="editNpcBtnClick(item.id)"
                                color="blue-grey darken-2"
                                v-if="authCheck()"
                                >编辑</v-btn
                              >
                            </v-card-actions>
                          </div>

                          <v-avatar class="ma-3" size="125" tile>
                            <v-img
                              :src="`/resources/ffxiv/npc/${item.code}.png`"
                            ></v-img>
                          </v-avatar>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8" sm="12" lg="8" xl="8">
        <v-card min-height="600" dark style="overflow: hidden">
          <v-card-title>
            拥有卡牌列表
            <v-spacer></v-spacer>
            {{ selectNpcItem.name }}
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-card
                  color="blue-grey darken-1"
                  dark
                  v-if="selectNpcItem.id != -1"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      absolute
                      color="green lighten-3"
                      height="4"
                      indeterminate
                    ></v-progress-linear>
                  </template>
                  <v-form v-show="authCheck()">
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="form.npcCardsList"
                            :items="cardsData"
                            filled
                            chips
                            color="blue-grey lighten-2"
                            label="Select"
                            item-text="name"
                            item-value="id"
                            multiple
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="remove(data.item)"
                              >
                                <v-avatar left>
                                  <v-img
                                    :src="
                                      '/resources/ffxiv/surface_icon/' +
                                      data.item.cardCode +
                                      '.png'
                                    "
                                  ></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template>
                                <v-list-item-avatar>
                                  <img
                                    :src="
                                      '/resources/ffxiv/surface_icon/' +
                                      data.item.cardCode +
                                      '.png'
                                    "
                                  />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    data.item.name
                                  }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-btn @click="saveNpcCards" v-if="authCheck()"
                          >保存 [{{ selectNpcItem.name }}]的卡牌</v-btn
                        >
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card>
              </v-col>
              <v-col cols="12">
                <CardInfoBox
                  v-for="item in npcCardsList"
                  :key="item.id"
                  :cardBoxData="item"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog.addNpc.show" max-width="800">
      <v-card dark :loading="dialog.addNpc.loading">
        <v-card-title>NPC新加</v-card-title>
        <v-card-text>
          <NpcCreateForm
            :callback="submitNpcFormCallback"
            :closeBtnEvent="closeDialog"
            :id="npcId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CardsEdit from "./cardsEdit";
export default CardsEdit;
</script>