import Vue from "vue";
import Component from "vue-class-component";
import NpcCreateForm from '@/components/ffxiv/NpcCreateForm.vue';
import CardInfoBox from '@/components/ffxiv/CardInfoBox.vue';
import { Card, NpcInfo } from "@/interface/FfxivDTO";
import { authCheck } from '@/utils/ffxiv-text-tool';


@Component({
  components: {
    NpcCreateForm,
    CardInfoBox
  },
})
export default class FFXIVNineCardsEdit extends Vue {

  authCheck = authCheck;

  npcId = -1;

  dialog = {
    addNpc: {
      show: false,
      loading: false
    }
  }

  npcCardsList: Card[] = [];
  form = {
    npcCardsList: []

  };

  npcInfoList: NpcInfo[] = []

  cardsData = [];

  selectNpcItem: NpcInfo = {
    id: -1,
    createdAt: new Date(),
    name: "",
    describe: "",
    code: "",
    updatedAt: new Date(),
    position: ""
  };

  mounted() {
    this.getAllNpcList();
    this.getAllCard();
  }

  saveNpcCards() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/npc/cards/save',
      method: 'put',
      data: {
        id: _this.selectNpcItem.id,
        cards: _this.form.npcCardsList
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.selectNpcCard(_this.selectNpcItem);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
    console.log(this.form.npcCardsList);
  }

  getAllCard() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/card',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.cardsData = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  remove(item: Card) {
    const index = this.form.npcCardsList.indexOf((item.id as never))
    if (index >= 0) this.form.npcCardsList.splice(index, 1)
  }

  selectNpcCard(item: NpcInfo) {
    this.selectNpcItem = item;
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/npc/cards/query/id',
      method: 'put',
      data: {
        id: item.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.npcCardsList = response.data.data;
        const tempList: never[] = [];
        response.data.data.forEach((value: Card) => {
          tempList.push((value.id as never));
        });
        _this.form.npcCardsList = tempList;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  newNpcBtnClick() {
    const _this = this;
    this.npcId = -1;
    this.dialog.addNpc.show = true;
    this.dialog.addNpc.loading = true;
    const timer = setTimeout(function () {
      _this.dialog.addNpc.loading = false;
      clearTimeout(timer);
    }, 1000)
  }


  editNpcBtnClick(id: number) {
    const _this = this;
    this.npcId = id;
    this.dialog.addNpc.show = true;
    this.dialog.addNpc.loading = true;
    const timer = setTimeout(function () {
      _this.dialog.addNpc.loading = false;
      clearTimeout(timer);
    }, 1000)
  }

  closeDialog() {
    this.dialog.addNpc.show = false;

  }

  submitNpcFormCallback() {
    this.getAllNpcList();
  }

  getAllNpcList() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/npc',
      method: 'get'

    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.npcInfoList = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }





}