







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NpcCreateForm extends Vue {
  @Prop() private callback!: Function;
  @Prop() private closeBtnEvent!: Function;
  @Prop() private id: number = -1;

  filesForm = {
    picture: "",
    pictureBase64: "",
    data: new Blob(),
    pictureLoading: false,
  };

  submitForm = {
    name: "",
    position: "",
    describe: "",
  };

  @Watch("id")
  getNpcInfo() {
    if (this.id == -1) {
      this.submitForm = {
        name: "",
        position: "",
        describe: "",
      };
      (this.filesForm.picture = ""), (this.filesForm.pictureBase64 = "");
    } else {
      this.findInfoById();
    }
  }

  deleteBtnEvent() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/npc',
        method: "delete",
        data: {
          id: _this.id,
        },
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.resetFormData();
          _this.callback();
          _this.closeBtnEvent();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  selectFileEvent() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.filesForm.picture = e.target.result;
      _this.filesForm.pictureBase64 = e.target.result.substring(
        "data:image/.png;base64,".length
      );
    };
    reader.readAsDataURL(_this.filesForm.data);
    this.filesForm.pictureLoading = true;
    const timer = setInterval(function () {
      _this.filesForm.pictureLoading = false;
      clearInterval(timer);
    }, 1000);
  }

  validate() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/npc',
        method: "put",
        data: {
          id: _this.id,
          name: _this.submitForm.name,
          describe: _this.submitForm.describe,
          position: _this.submitForm.position,
          pictureBase64: _this.filesForm.pictureBase64,
        },
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.callback();
          _this.closeBtnEvent();
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  findInfoById() {
    const _this = this;
    _this
      .$axios({
        url: '/api/v1/ffxiv/npc/id',
        method: "put",
        data: {
          id: _this.id,
        },
      })
      .then(function (response) {
        if (response.data.code === 0) {
          _this.$toast.success(response.data.message);
          _this.submitForm = response.data.data;
          _this.filesForm.picture = `/resources/ffxiv/npc/${response.data.data.code}.png`;
          _this.filesForm.pictureBase64 = "";
        } else {
          _this.$toast.error(response.data.message);
        }
      })
      .catch(function (err) {
        _this.$toast.error(err);
      });
  }

  resetFormData() {
    this.submitForm = {
      name: "",
      position: "",
      describe: "",
    };
    this.filesForm = {
      picture: "",
      pictureBase64: "",
      data: new Blob(),
      pictureLoading: false,
    };
  }
}
